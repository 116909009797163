.about {
    height: 100vh;
    display: flex;
    align-items: center;
}

.about-left{
    flex:1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
}

.about-right{
    flex:1;
}

.about-card.bg{
    position: absolute;
    top: 50px;
    left: 50px;
    background-color: #333;
}

.about-card {
    width: 60%;
    height: 70vh;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
}

.about-img {
width: 100%;
height: 100%;
object-fit: cover;
}

.about-award-img {
    width: 110px;
    height: 120px;
    border-radius: 20px;
}

.about-title {
    font-weight: 400;
}

.about-sub {
    margin: 20px 0px;
}

.about-desc {
    font-weight: 300;
}

.about-award{
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.about-award-img {
    height: 20%;
    width: 20%;
}

.a-awards-texts{
    width: 70%;
}
.a-award-title {
    font-weight: bold;
    color: #555;
    margin-bottom: 10px;
}

@media screen and (max-width: 480px) {
    .about {
        flex-direction: column;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 150px;
    }

    .about-left {
        width: 100%;
    }

    .about.about-card {
        height: 30vh;
    }

    .about-card.bg, .about-award {
        display: none;
    }

    .about-right {
        padding: 20px;
    }
}