.i {
    display: flex;
    height: 100vh;
}

.i-left {
 flex: 1;
 display: flex;
 align-items: center;
 justify-content: center;
}

.i-right {
    flex: 1;
    position: relative ;
   }
   
.i-left-wrapper {
    padding: 50px;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.i-intro {
    font-size:  50px;
    font-weight: 300;
}

.i-name {
    font-size: 60px;
}
.i-title {
    height: 50px;
    overflow: hidden;
}

.i-title-wrapper {
    height: 100%;
    animation: move 10s ease-in-out infinite alternate;
}

@keyframes move {
    50%{
        transform: translateY(-50px);
    }
    100%{
        transform: translateY(100px);
    }

}

.i-title-item {
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    color: #02bf80;
    display: flex;
    align-items: center;
}

 .i-img {
    width: 80%;
    height: 80%;
    object-fit: cover;
    position: absolute;

    
}  

.i-bg {
    /* clip-path: polygon(100% 0%, 100% 49%, 100% 100%, 25% 100%, 0% 50%, 25% 0%); */
    width: 100%;
    height: 100%;
    /* background-color: #02bf80;  */
    background-color: white;
    position: absolute;
    top: 0;
    right: 0;

}

@media screen and (max-width: 480px){
    .i {
        flex-direction: column;
    }

    .i-left-wrapper {
        padding: 10px;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
    }

    .i-desc {
        display: none;
    }
}
